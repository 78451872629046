import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useWatch } from 'react-hook-form'
import type { Control, ControllerRenderProps } from 'react-hook-form'
import { AutocompleteItem } from 'types/FormFields'
import { useState, useEffect } from 'react'
import { isEqual } from 'lodash'
import { ManageAccounts } from '@mui/icons-material'

interface UserInviteAutocompleteProps {
  control: Control<any>
  options: AutocompleteItem[]
  label: string
  fieldName: string
  filterSelectedOptions?: boolean
  fullWidth?: boolean
  size?: 'small' | 'medium'
  loading?: boolean
  limitTags?: number
  onEndAdornmentClick?: (field: ControllerRenderProps<any, string>) => void
}

export default function UserInviteAutocomplete({
  control,
  options,
  label,
  fieldName,
  filterSelectedOptions = false,
  fullWidth = false,
  size = 'small',
  loading = false,
  limitTags = 3,
  onEndAdornmentClick,
}: UserInviteAutocompleteProps): JSX.Element {
  const [inputValue, setInputValue] = useState('')

  // Watch the value of the field from the control
  const watchedValue = useWatch({
    control,
    name: fieldName,
  })

  useEffect(() => {
    setValue(watchedValue || [])
    // if (onEndAdornmentClick) onEndAdornmentClick()
  }, [watchedValue])

  const [value, setValue] = useState<AutocompleteItem[]>(watchedValue || [])

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={[]}
      render={({ field }): JSX.Element => (
        <Autocomplete
          {...field}
          value={value}
          fullWidth={fullWidth}
          size={size}
          limitTags={limitTags}
          options={options as AutocompleteItem[]}
          filterSelectedOptions={filterSelectedOptions}
          isOptionEqualToValue={(
            option: AutocompleteItem,
            value: AutocompleteItem
          ): boolean => isEqual(option.id, value.id)}
          renderOption={(props, option): JSX.Element => {
            return (
              <Box component="li" {...props} key={option.id}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>{option.label}</Typography>
                  <Typography
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: '0.65rem',
                      color: 'gray',
                    }}
                  >
                    {option.description}
                  </Typography>
                </Box>
              </Box>
            )
          }}
          renderInput={(params): JSX.Element => (
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
              <TextField
                {...params}
                label={label}
                helperText={value
                  .filter(v => v.description)
                  .map(v => v.description)
                  .join(', ')}
              />
              {onEndAdornmentClick ? (
                <IconButton
                  aria-label="Roles matrix"
                  onClick={(): void => {
                    onEndAdornmentClick(field)
                  }}
                  edge="end"
                  size="small"
                  disableFocusRipple
                  sx={{ ml: '.3125rem', borderRadius: 0 }}
                  color="info"
                >
                  <ManageAccounts />
                </IconButton>
              ) : null}
            </Box>
          )}
          onChange={(event, newValue): void => {
            setValue(newValue as AutocompleteItem[])
            field.onChange(newValue)
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue): void => {
            setInputValue(newInputValue)
          }}
          loading={loading}
          multiple
          disableCloseOnSelect
        />
      )}
    />
  )
}
