import { useState, useEffect, ReactNode } from 'react'

import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

import { loginRequest } from 'authConfig'
import {
  checkRolesAndPermissionsInStorage,
  getPermissionsFromStorage,
  getRolesFromStorage,
  setRolesAndPermissionsInStorage,
} from 'utils/storageUtils'
import { Box, Button, Typography } from '@mui/material'

interface AuthenticationGuardProps {
  component: ReactNode
  requiredPermissions?: string[]
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  component,
  requiredPermissions = [],
}: AuthenticationGuardProps): JSX.Element => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
  const [isAuthorized, setIsAuthorized] = useState(false)

  const onLoad = (): void => {
    // check either the ID token or a non-expired storage entry for the required claims
    if (
      (!activeAccount ||
        !activeAccount?.idTokenClaims?.extension_AppUserRole ||
        !activeAccount?.idTokenClaims?.extension_RolePermissions) &&
      !checkRolesAndPermissionsInStorage(activeAccount)
    ) {
      return
    }

    const permissionsString: string | undefined =
      typeof activeAccount?.idTokenClaims?.extension_RolePermissions ===
      'string'
        ? (activeAccount.idTokenClaims.extension_RolePermissions as string)
        : undefined
    const permissionsArray =
      permissionsString?.split(',') || getPermissionsFromStorage(activeAccount)

    const hasRequiredPermission = requiredPermissions.every(permission =>
      permissionsArray.includes(permission)
    )

    setIsAuthorized(hasRequiredPermission)

    if (hasRequiredPermission) {
      const rolesString: string | undefined =
        typeof activeAccount?.idTokenClaims?.extension_AppUserRole === 'string'
          ? (activeAccount.idTokenClaims.extension_AppUserRole as string)
          : undefined
      const rolesArray =
        rolesString?.split(',') || getRolesFromStorage(activeAccount)
      setRolesAndPermissionsInStorage(
        activeAccount,
        rolesArray,
        permissionsArray
      )
    } else {
      instance.logoutRedirect()
    }
  }

  useEffect(() => {
    onLoad()
  }, [activeAccount])

  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      {isAuthorized ? (
        <div>{component}</div>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Typography variant="h3">Access Denied</Typography>
          <Typography variant="body1" mt={1}>
            You are unauthorized to view this content.
          </Typography>
          <Button
            variant="outlined"
            onClick={(): void => {
              instance.logoutRedirect()
            }}
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </Box>
      )}
    </MsalAuthenticationTemplate>
  )
}
