import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser'
// import { datadogRum } from '@datadog/browser-rum'
import '@datadog/browser-rum/bundle/datadog-rum'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from 'App'
// import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { msalConfig } from 'authConfig'
import reportWebVitals from 'reportWebVitals'
import theme from 'theme'

import './styles/index.scss'

// datadogRum.init({
//   applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
//   clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
//   site: process.env.REACT_APP_DATADOG_SITE,
//   service: 'rts-user-management',
//   env: process.env.REACT_APP_ENV,
//   version: '0.1.0',
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 100,
//   trackResources: true,
//   trackLongTasks: true,
//   trackUserInteractions: true,
//   defaultPrivacyLevel: 'mask-user-input',
// })

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback(event => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    (event.payload as AuthenticationResult).account
  ) {
    msalInstance.setActiveAccount(
      (event.payload as AuthenticationResult).account
    )
  }
})

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  //<StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <App instance={msalInstance} />
    </BrowserRouter>
  </ThemeProvider>
  //</StrictMode>
)

reportWebVitals()
