import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import AutoCompleteCategory from 'components/atoms/AutoCompleteCategory'
import UserInviteAutocomplete from 'components/atoms/UserInviteAutocomplete'
import { useForm } from 'react-hook-form'
import {
  AutocompleteGroupItem,
  AutocompleteItem,
  InviteUserFormFields,
} from 'types/FormFields'
import { UsersType } from 'types/user'
import { getRegex } from 'utils/helpers'

const UserDetails = forwardRef(
  (
    {
      user,
      loadingRoles,
      loadingBillingAcc,
      loadingSites,
      loadingReports,
      roleList = [],
      billingAccList = [],
      siteList = [],
      reportList = [],
      onBillingAccChange,
      onSaveUser,
      onValidityChange,
    }: {
      user: UsersType
      loadingRoles: boolean
      loadingBillingAcc: boolean
      loadingSites: boolean
      loadingReports: boolean
      roleList: AutocompleteItem[]
      billingAccList: AutocompleteItem[]
      siteList: AutocompleteGroupItem[]
      reportList: AutocompleteItem[]
      onBillingAccChange: (billingAccList: AutocompleteItem[]) => void
      onSaveUser: (data: InviteUserFormFields, error: any) => void
      onValidityChange: (valid: boolean) => void
    },
    ref
  ) => {
    const [errorMsg] = useState('')
    const {
      register,
      handleSubmit,
      setValue,
      control,
      watch,
      formState: { errors, isValid, isDirty },
    } = useForm<InviteUserFormFields>({ mode: 'onChange' })
    const [selectedSites, setSelectedSites] = useState<AutocompleteGroupItem[]>(
      []
    )

    const watchBillingAccount = watch('billingAccounts')

    useEffect(() => {
      onBillingAccChange(watchBillingAccount)
    }, [watchBillingAccount])

    useEffect(() => {
      const billingAccounts = user.billingAccounts.map(option => ({
        id: option.id,
        label: option.name,
        sites: option.sites,
        accountNumber: option.accountNumber,
        pelloId: option.pelloId,
      }))
      setValue('userEmail', user.emailAddress)
      setValue('userFullName', user.fullName)
      setValue(
        'billingAccounts',
        billingAccounts.map(({ id, label, accountNumber, pelloId }) => ({
          id,
          label,
          accountNumber,
          pelloId,
        }))
      )
      setValue(
        'accessRoles',
        user.accessRoles?.map(option => ({
          label: option.roleName,
          id: option.roleId,
          description: option.description,
        }))
      )
      setValue(
        'reportIds',
        reportList?.filter(option => user.reportIds?.includes(option.id))
      )
    }, [user])

    useEffect(() => {
      if (siteList && siteList.length && user) {
        const selectedSitesList: string[] = []

        user.billingAccounts.map(option => {
          option.sites.map(sOption => {
            selectedSitesList.push(sOption.id)
          })
        })
        const selectedSitesWithGroup = siteList.filter(site => {
          return selectedSitesList.includes(site.id)
        })

        setSelectedSites(selectedSitesWithGroup)

        setValue('sites', selectedSitesWithGroup)
      }
    }, [user, siteList])

    useEffect(() => {
      if (onValidityChange) {
        onValidityChange(isValid && isDirty)
      }
    }, [isValid, isDirty])

    useImperativeHandle(ref, () => ({
      submitForm: (): void => {
        handleSubmit(onSaveUser)()
      },
    }))

    return (
      <Box
        component="form"
        onSubmit={handleSubmit(onSaveUser)}
        sx={{
          width: '100%',
          background: '#fff',
          pt: 2,
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              label="Email Address"
              disabled={true}
              autoFocus
              {...register('userEmail', {
                required: 'Email is Required.',
                pattern: {
                  value: getRegex('email'),
                  message: 'Invalid Email Address.',
                },
              })}
              error={errors?.userEmail ? true : false}
              onBlur={(
                e: React.FocusEvent<
                  HTMLInputElement | HTMLTextAreaElement,
                  Element
                >
              ): void => setValue('userEmail', e.target.value.trim())}
              helperText={
                errors?.userEmail && errors.userEmail?.message
                  ? String(errors.userEmail.message)
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              label="Full Name"
              color="primary"
              {...register('userFullName', {
                required: 'Name is Required.',
                pattern: {
                  value: getRegex('name'),
                  message:
                    'Name must be between 2-256 characters in length and cannot contain < or >.',
                },
              })}
              onBlur={(
                e: React.FocusEvent<
                  HTMLInputElement | HTMLTextAreaElement,
                  Element
                >
              ): void => setValue('userFullName', e.target.value.trim())}
              error={errors?.userFullName ? true : false}
              sx={{ fontSize: '0.6rem' }}
              helperText={
                errors?.userFullName && errors.userFullName?.message
                  ? String(errors.userFullName.message)
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserInviteAutocomplete
              control={control}
              options={billingAccList}
              label="Billing Account"
              fieldName="billingAccounts"
              loading={loadingBillingAcc}
            />
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              {watchBillingAccount?.map((account, index: number) => (
                <Grid
                  container
                  spacing={1}
                  key={account.id}
                  alignItems="center"
                  sx={{ marginBottom: 1 }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body1">{account.label}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder={`${account.label}`}
                      inputProps={{
                        maxLength: 10,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      label="Pello ID"
                      autoFocus
                      {...register(`billingAccounts.${index}.pelloId`, {
                        //required: 'This field is required',
                        maxLength: {
                          value: 10,
                          message: 'Maximum length is 10 characters',
                        },
                        pattern: {
                          value: /^[0-9]*$/, // Allow only numeric characters
                          message: 'Only numeric characters are allowed',
                        },
                      })}
                      error={!!errors?.billingAccounts?.[index]?.pelloId}
                      helperText={
                        errors?.billingAccounts?.[index]?.pelloId?.message
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteCategory
              control={control}
              options={siteList}
              multiple={true}
              label="Sites"
              fieldName="sites"
              loading={loadingSites}
              setValue={setValue}
              selectedValues={selectedSites}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserInviteAutocomplete
              control={control}
              options={roleList}
              label="Role(s)"
              filterSelectedOptions
              fieldName="accessRoles"
              loading={loadingRoles}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserInviteAutocomplete
              control={control}
              options={reportList}
              label="Select Report(s)"
              filterSelectedOptions
              fieldName="reportIds"
              loading={loadingReports}
            />
          </Grid>
          <Grid item xs={12} sx={{ p: 0 }}>
            <Divider>
              {errorMsg ? (
                <FormHelperText
                  error={errorMsg !== ''}
                  sx={{ textAlign: 'center' }}
                >
                  {errorMsg}
                </FormHelperText>
              ) : null}
            </Divider>
          </Grid>
        </Grid>
      </Box>
    )
  }
)

UserDetails.displayName = 'UserDetails'

export default UserDetails
