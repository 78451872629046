export const drawerMenu = [
  {
    text: 'Users',
    icon: 'personOutlined',
    path: '/users',
    end: true,
  },
  {
    text: 'Roles',
    icon: 'accessibilityOutlined',
    path: '/roles',
    end: true,
  },
]

export const settingsMenu = [
  {
    text: 'Profile',
    icon: 'homeOutlined',
    path: '/profile',
    end: true,
  },
]
