// import { useAuth0 } from '@auth0/auth0-react'
import {
  AuthError,
  AuthenticationResult,
  EventMessage,
  EventType,
  IPublicClientApplication,
  InteractionRequiredAuthError,
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { Box } from '@mui/material'
import { b2cPolicies, loginRequest } from 'authConfig'
import { AuthenticationGuard } from 'components/atoms/authenticationGuard'
// import Loading from 'components/molecules/Loading'
import AccessDenied from 'components/pages/401'
import Forbidden from 'components/pages/403'
import NotFound from 'components/pages/404'
import Home from 'components/pages/Home'
import Logout from 'components/pages/Logout'
import Profile from 'components/pages/Profile'
import Users from 'components/pages/Users'
import Roles from 'components/pages/Roles'
import { Provider } from 'jotai'
import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import 'styles/App.scss'
import { compareIssuingPolicy } from 'utils/claimUtils'

const App = ({
  instance,
}: {
  instance: IPublicClientApplication
}): JSX.Element => {
  useEffect(() => {
    const callbackId = instance.addEventCallback(
      async (event: EventMessage) => {
        if (
          (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
          (event.payload as AuthenticationResult).account
        ) {
          const authResult = event.payload as AuthenticationResult
          /**
           * For the purpose of setting an active account for UI update, we want to consider only the auth
           * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
           * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
           * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
           */
          if (
            compareIssuingPolicy(
              authResult.idTokenClaims,
              b2cPolicies.names.editProfile
            )
          ) {
            // retrieve the account from initial sign-in to the app
            const originalSignInAccount = instance
              .getAllAccounts()
              .find(
                account =>
                  account.idTokenClaims != null &&
                  account.idTokenClaims.oid ===
                    authResult.account?.idTokenClaims?.oid &&
                  account.idTokenClaims.sub ===
                    authResult.account?.idTokenClaims?.sub &&
                  compareIssuingPolicy(
                    account.idTokenClaims,
                    b2cPolicies.names.signUpSignIn
                  )
              )

            const signUpSignInFlowRequest = {
              authority: b2cPolicies.authorities.signUpSignIn.authority,
              account: originalSignInAccount,
            }

            // silently login again with the signUpSignIn policy
            instance.ssoSilent(signUpSignInFlowRequest)

            try {
              await instance.ssoSilent(signUpSignInFlowRequest)
            } catch (err) {
              if (err instanceof InteractionRequiredAuthError) {
                await instance
                  .loginPopup({
                    ...signUpSignInFlowRequest,
                    scopes: loginRequest.scopes,
                  })
                  .catch(error => {
                    // handle error
                    console.error('login issue:', error)
                  })
              } else {
                // handle error
              }
            }
          }

          /**
           * Below we are checking if the user is returning from the reset password flow.
           * If so, we will ask the user to reauthenticate with their new password.
           * If you do not want this behavior and prefer your users to stay signed in instead,
           * you can replace the code below with the same pattern used for handling the return from
           * profile edit flow
           */
          if (
            compareIssuingPolicy(
              authResult.idTokenClaims,
              b2cPolicies.names.forgotPassword
            )
          ) {
            const signUpSignInFlowRequest = {
              authority: b2cPolicies.authorities.signUpSignIn.authority,
              scopes: loginRequest.scopes,
            }
            instance.loginRedirect(signUpSignInFlowRequest)
          }
        }

        if (event.eventType === EventType.LOGIN_FAILURE) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (
            event.error &&
            (event.error as AuthError)?.errorMessage.includes('AADB2C90118')
          ) {
            const resetPasswordRequest = {
              authority: b2cPolicies.authorities.forgotPassword.authority,
              scopes: [],
            }
            instance.loginRedirect(resetPasswordRequest)
          }
        }
      }
    )

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [instance])

  return (
    <MsalProvider instance={instance}>
      <Provider>
        <Box className="app" data-testid="app" component="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/users"
              element={
                <AuthenticationGuard
                  component={<Users />}
                  requiredPermissions={[
                    'UM:Users:AllCreate',
                    'UM:Users:AllRead',
                    'UM:Users:AllWrite',
                    'UM:Users:AllDelete',
                  ]}
                />
              }
            />
            <Route
              path="/roles"
              element={
                <AuthenticationGuard
                  component={<Roles />}
                  requiredPermissions={[
                    'UM:Users:AllCreate',
                    'UM:Users:AllRead',
                    'UM:Users:AllWrite',
                    'UM:Users:AllDelete',
                  ]}
                />
              }
            />
            <Route
              path="/profile"
              element={<AuthenticationGuard component={<Profile />} />}
            />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/401" element={<AccessDenied />} />
            <Route path="/403" element={<Forbidden />} />
          </Routes>
        </Box>
      </Provider>
    </MsalProvider>
  )
}

export default App
