import MuiAlert, { AlertProps } from '@mui/material/Alert'
import Slide, { SlideProps } from '@mui/material/Slide'
import Snackbar from '@mui/material/Snackbar'
import { SxProps, Theme } from '@mui/material/styles'
import { forwardRef } from 'react'

const SlideTransition = (props: SlideProps): JSX.Element => {
  return <Slide {...props} direction="up" />
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

interface Props {
  open: boolean
  message: string
  type: 'error' | 'info' | 'success' | 'warning'
  horizontal?: 'center' | 'left' | 'right'
  vertical?: 'bottom' | 'top'
  autoHideDuration?: number | null
  sx?: SxProps<Theme>
  onCloseCB?: (event?: React.SyntheticEvent | Event, reason?: string) => void
}

const NotificationBar = ({
  open = false,
  message,
  vertical = 'bottom',
  horizontal = 'center',
  autoHideDuration = null,
  type = 'success',
  sx = [],
  onCloseCB,
}: Props): JSX.Element => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (onCloseCB) onCloseCB(event, reason)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      TransitionComponent={SlideTransition}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default NotificationBar
